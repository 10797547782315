body {
  margin: 0 auto;
  font-family: 'Roboto';
  font-style: normal;
  background: #F4F4F4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.auth-logo {
  max-width: 300px;
  width: 50%
}

.footer-pin {
  position: relative;
  left: 0;
  bottom: 0;
}

.inputPhoneNumber .PhoneInputInput {
  border: solid rgba(0, 0, 0, 0.42);
  border-width: 0px 0 1px;
  padding-bottom: 10px;
  font-size: 20px;
  color: #00679c;
  outline: 0;
}

.inputPhoneNumber.error .PhoneInputInput {
  border-color: #C80021;
  border-width: 0px 0 2px;
}

.inputPhoneNumber .PhoneInputInput::placeholder {
  color: #757575;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  /* Firefox */
}

.inputPhoneNumber .PhoneInputInput :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.inputPhoneNumber .PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #757575;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.inputPhoneNumber .PhoneInputCountryIcon {
  width: 35px;
  height: auto;
}

.custom-wrapper {
  min-height: 330px !important;
  margin-bottom: 0 !important;
  padding: 0 20px !important;
  box-shadow: none !important;

}

.editot-text {
  padding-top: 48px !important;
  margin-left: 1px !important;
}

.editot-text h1 {
  font-size: 28px;
  line-height: 1.43;
}

.custom-tab .Mui-selected {
  background-color: #004172;
  color: #fff !important;
}

.public-DraftStyleDefault-block {
  direction: ltr !important;
}